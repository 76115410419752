import React, { useContext } from "react";

import { SidebarSecondary } from "../../../elements/frontend/src/components";

import { DashboardDispatch, DashbordContext } from "../../../views/Private/Home";
import ContentInvestor from "./ContentInvestor";
import { Wrapper } from "./styled.investor";

const InvestorContent = () => {
  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  // to change the UI according to click event. User can navigate between menu components
  const changeCurrentStep = (stepId) => {
    if (stepId) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          investor_content: {
            ...dashboardContext.investor_content,
            currentStep: stepId,
            showStatus:
              stepId === "OVERVIEW" || !dashboardContext.investor_content.showStatus ? false : true,
            showDetails:
              stepId === "OVERVIEW" || dashboardContext.investor_content.readOnly ? false : true,
          },
          scrollTo: "",
          detailsContentLoading: true,
        },
      });
    }
  };

  return (
    <Wrapper>
      <SidebarSecondary
        steps={dashboardContext.investor_content.steps}
        currentStep={dashboardContext.investor_content.currentStep}
        showStatus={dashboardContext.investor_content.showStatus}
        showDetails={dashboardContext.investor_content.showDetails}
        changeCurrentStep={changeCurrentStep}
      />
      <ContentInvestor />
    </Wrapper>
  );
};

export default InvestorContent;
