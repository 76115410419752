import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const FieldSet = styled("fieldset")(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "4px",
  padding: "0 10px",
  "& legend": {
    fontSize: "12px",
    color: theme.palette.grey[700],
    padding: "0 7px",
  },
}));

export const TableWrapper = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  width: "100%",

  "& th": {
    fontWeight: 600,
    width: "100%",
    maxWidth: "30%",
    backgroundColor: rgba(theme.palette.grey[200], 0.6),
  },
  "&, th, td": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& th, & td": {
    textAlign: "left",
    padding: "5px 10px",
    fontSize: "0.95rem",
  },
  "& td.action_buttons": {
    width: "100px",
    textAlign: "center",
  },
  "& .bgInfo": {
    backgroundColor: rgba(theme.palette.primary.main, 0.2),
  },
  "& ul": {
    marginLeft: "20px",
  },
  "& ul.space": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    "& li": {
      marginRight: "40px",
    },
  },
}));
