import React, { useContext } from "react";

import { FadeIn } from "../../../../elements/frontend/src/components";

import DetailsInvestor from "../../../../components/DetailsInvestor";
import OverviewInvestor from "../../../../components/OverviewInvestor";
import StatusInvestor from "../../../../components/StatusInvestor";
import { DashbordContext } from "../../../../views/Private/Home";
import { Wrapper } from "./styled.contentInvestor";

const ContentInvestor = () => {
  const dashboardContext = useContext(DashbordContext);

  return (
    <Wrapper>
      <FadeIn>
        {dashboardContext.investor_content.currentStep === "OVERVIEW" && <OverviewInvestor />}
        {dashboardContext.investor_content.currentStep === "STATUS" && <StatusInvestor />}
        {dashboardContext.investor_content.currentStep === "DETAILS" && <DetailsInvestor />}
      </FadeIn>
    </Wrapper>
  );
};

export default ContentInvestor;
