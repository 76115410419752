import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import { Dialog, DialogTitle, DialogActions, DialogContent, TextField } from "@mui/material";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { lighten } from "@mui/system";
import { useMediaQuery } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const InvestorWelcomeDialog = ({
  type = "warning",
  dialogTitle,
  contentText,
  textField,
  cancelMessage,
  setCancelMessage,
  secondaryDialogOpen,
  secondaryDialogClose,
  maxWidth = "xs",
  eventHandler,
  actionButtonText,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  return (
    <div>
      {
        <Dialog
          open={secondaryDialogOpen}
          onClose={() => secondaryDialogClose}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby={type}
          sx={{
            "& .MuiPaper-root": {
              width: maxWidth,
              borderRadius: "10px",
              "& .MuiDialogTitle-root": {
                backgroundColor: type === "warning" ? lighten(theme.palette.error.main, 0.8) : null,
              },
              "& .MuiDialogContent-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
              "& .MuiDialogActions-root": {
                padding: "15px",
                [theme.breakpoints.up("md")]: {
                  padding: "24px",
                },
              },
            },
          }}
        >
          <DialogTitle
            align="center"
            color="primary"
            sx={{
              padding: "15px",
              [theme.breakpoints.up("md")]: {
                padding: "34px 34px 10px",
              },
              fontWeight: "bold",
            }}
          >
            {dialogTitle}
          </DialogTitle>
          <DialogContent align="center">{contentText}</DialogContent>
          {textField && (
            <TextField
              label={t("company/common:personal_talk.cancel.text_field.caption")}
              placeholder={t("company/common:personal_talk.cancel.text_field.placeholder")}
              value={cancelMessage}
              onChange={(e) => setCancelMessage(e.target.value)}
              inputProps={{
                maxLength: 1000,
              }}
              variant={"filled"}
              multiline
              minRows={5}
              maxRows={5}
              sx={{
                margin: "15px",
                [theme.breakpoints.up("md")]: {
                  margin: "24px",
                },
                fontWeight: "bold",
              }}
            />
          )}
          <div
            className="MuiDialogActions-root"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 10px 24px",
            }}
          >
            <DialogActions>
              <Button
                variant="contained"
                color={type === "primary" ? "info" : "primary"}
                size={isMobile ? "small" : "medium"}
                sx={{
                  px: 3,
                  fontWeight: "bold",
                  color: type === "warning" ? "white" : "secondary",
                }}
                onClick={eventHandler}
              >
                {actionButtonText}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      }
    </div>
  );
};
