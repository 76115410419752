import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { rgba } from "polished";

import Box from "@mui/material/Box";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Section,
  Card,
  SelectColumnFilter,
  LoadingIndicator,
  SecondaryDialog,
} from "../../elements/frontend/src/components";
import { isValidLengthString } from "../../elements/frontend/src/common";
import { useClients, useAccounts } from "../../elements/frontend/src/hooks";
import { useGetCategoryTreesQuery } from "../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { CategoryType } from "../../elements/frontend/src/components/CategoriesDropdown/interfaces";

import { DashbordContext, DashboardDispatch } from "../../views/Private/Home";
import ProfileTable from "./ProfileTable";
import ProfileForm from "./ProfileForm";

import { OverviewInvestorTable } from "../OverviewInvestorTable";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";

const OverviewInvestor = () => {
  const { t } = useTranslation(["investor/common"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const { getAccount } = useAccounts();
  const { updateClient } = useClients();

  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();
  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);
  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const [expandedTransactionOffers, setExpandedTransactionOffers] = useState([]);
  const [loadingTransactions, setLoadingTransactions] = useState(false);

  const [initial, setInitial] = useState(true);

  const [tableProfiles, setTableProfiles] = useState([]);
  const [investorProfile, setInvestorProfile] = useState({});
  const [profileNameExist, setProfileNameExist] = useState(false);
  const [expandAccordion, setExpandAccordion] = useState(false);
  const [fileName, setFileName] = useState(undefined);
  const [secondaryDialogOpen, setSecondaryDialogOpen] = useState(false);

  const initialInputFieldValues = useMemo(() => {
    return {
      profile_name: "",
      categories: [],
      sub_categories: [],
      phases: [...currentUser?.datastore?.phases?.map((item) => item.value)],
      region: ["DEU"],
      invest: "",
      revenue: "",
      ebitda: "",
      stake: [...currentUser?.datastore?.stake?.map((item) => item.value)],
      types: [...currentUser?.datastore?.types.map((item) => item.value)],
      period: [...currentUser?.datastore?.period.map((item) => item.value)],
      contribution: {
        strategic_investor: false,
        financial_investor: false,
        wachstums_investor: false,
      },
    };
  }, [
    currentUser.datastore.period,
    currentUser.datastore.phases,
    currentUser.datastore.stake,
    currentUser.datastore.types,
  ]);

  const selectedClientId = currentUser.selectedClient.client_id;

  const selectedClient = useMemo(() => {
    if (!currentUser.clients) return {};
    return currentUser.clients.find((client) => client.client_id === selectedClientId);
  }, [currentUser.clients, selectedClientId]);

  // for the data saved in scope "investment"
  const selectedClientInvestmentData = useMemo(() => {
    if (!selectedClient.metadata) return;
    const scopeInvestment = selectedClient.metadata.find((item) => item.scope === "investment");
    if (scopeInvestment) {
      return scopeInvestment.data;
    }
    return {};
  }, [selectedClient.metadata]);

  // for the data saved in scope "profile" in investorProfiles field
  const selectedClientInvestorProfiles = useMemo(() => {
    const scopeProfile = selectedClient.metadata.find((item) => item.scope === "profile");
    const investorprofiles = scopeProfile?.data?.investorProfiles;
    if (investorprofiles) {
      return investorprofiles;
    }
  }, [selectedClient.metadata]);

  useEffect(() => {
    if (selectedClientInvestorProfiles?.length) {
      setTableProfiles(selectedClientInvestorProfiles);
      setInvestorProfile(initialInputFieldValues);
    } else {
      const typeOfCategory = typeof selectedClientInvestmentData.categories[0];
      if (typeOfCategory === "string") {
        setInvestorProfile(selectedClientInvestmentData);
      } else if (typeOfCategory !== undefined) {
        const newCategries = selectedClientInvestmentData.categories.map((item) => item.cat);
        const newInvestmentData = {
          ...selectedClientInvestmentData,
          categories: newCategries,
        };
        setInvestorProfile(newInvestmentData);
      }
    }
  }, [initialInputFieldValues, selectedClientInvestmentData, selectedClientInvestorProfiles]);

  // // stepsObject from locales
  const stepsObject = t("transaction_tables.process_flow.steps", {
    returnObjects: true,
  });

  // to get all account transactions
  useEffect(() => {
    setLoadingTransactions(true);
    getAccount()
      .then((response) => {
        // transaction offers
        const newTransactionOffers = response.transactions.filter(
          (item) =>
            item.state.status.current_stage === "investor_matching" &&
            item.state.status.next_steps[0] === "investor_final"
        );

        const expandedTransactionOffers = newTransactionOffers.map((item) => ({
          profile_name: item.profile_name, // investor profile name here
          client_name: item.client_name,
          last_action: stepsObject[item.state.status.last_step],
          date: new Date(item.state.status.last_step_timestamp).toLocaleDateString(),
          next_action: stepsObject.advisor_accepted,
          transaction_id: item.transaction_id,
          advisor: item.advisor,
        }));

        setExpandedTransactionOffers(expandedTransactionOffers);
        setLoadingTransactions(false);
      })
      .catch((err) => {
        setLoadingTransactions(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for table-1, to list the transaction offers in Card-1
  const table_1_columns = useMemo(
    () => [
      {
        Header: "Investor Profile Name",
        accessor: "profile_name",
      },
      {
        Header: t("transaction_tables.columns.company_name"),
        accessor: "client_name",
      },
      {
        Header: t("transaction_tables.columns.last_action"),
        accessor: "last_action",
        Filter: SelectColumnFilter,
        filter: "includes",
      },
      {
        Header: t("transaction_tables.columns.date"),
        accessor: "date",
      },
      {
        Header: t("transaction_tables.columns.accept_reject"),
        accessor: "accept",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [t]
  );

  // to check whether input fields are valid or not
  const isValid = (name, initial = true) => {
    if (initial && dashboardContext.form_state.initial) return true;
    switch (name) {
      case "profile_name":
        return isValidLengthString(investorProfile[name], 1);
      case "categories":
      case "phases":
      case "region":
      case "stake":
      case "types":
      case "period":
        return investorProfile[name]?.length > 0;
      case "invest":
      case "revenue":
      case "ebitda":
        return isValidLengthString(investorProfile[name], 1);
      default:
        return false;
    }
  };

  const handleInvestorProfileChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    if (profileNameExist) {
      setProfileNameExist(false);
    }
    setInvestorProfile({
      ...investorProfile,
      [name]: value,
    });
  };

  // handle change event in CategoriesDropdown component
  const handleCategoriesSelect = (categories, type) => {
    // handle change for "categories"
    const categoryIds = categories.map((category) => category.value);

    if (type === CategoryType.CATEGORY && categoryTrees) {
      const subCategoryIds = investorProfile.sub_categories || [];
      const updatedSubCategoryIds = removeAllChildSubCategoriesWithoutSelectedRootCategory(
        categoryIds,
        subCategoryIds,
        categoryTrees
      );
      setInvestorProfile({
        ...investorProfile,
        categories: categoryIds,
        sub_categories: updatedSubCategoryIds,
      });
    }

    if (type === CategoryType.SUB_CATEGORY) {
      setInvestorProfile({
        ...investorProfile,
        sub_categories: categoryIds,
      });
    }
  };

  // handle change event in Autocomplete elements
  const handleAutoCompleteSelect = (e, value, field) => {
    if (value) {
      const newValue = value.map((item) => (field === "region" ? item.code : item.value));
      setInvestorProfile({
        ...investorProfile,
        [field]: newValue,
      });
    }
  };

  // to check whether checkboxes checked or not
  const handleConfirmCheck = (field, chkName) => {
    return (e) => {
      const isChecked = e.target.checked;
      setInvestorProfile({
        ...investorProfile,
        [field]: {
          ...investorProfile[field],
          [chkName]: isChecked,
        },
      });
    };
  };

  // to expand accordion in regards to selected profile_name
  const handleChangeExpandAccordion = (e, isExpanded, profileName) => {
    setExpandAccordion(isExpanded ? profileName : false);
  };

  const validationChecker = () => {
    if (dashboardContext.form_state.initial) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          form_state: {
            initial: false,
            valid: dashboardContext.form_state.valid,
          },
        },
      });
    }
    const allFieldsAreValid =
      isValid("profile_name", false) &&
      isValid("categories", false) &&
      isValid("phases", false) &&
      isValid("region", false) &&
      isValid("invest", false) &&
      isValid("revenue", false) &&
      isValid("ebitda", false) &&
      isValid("stake", false) &&
      isValid("types", false) &&
      isValid("period", false);

    return allFieldsAreValid;
  };

  const handleAddProfileToProfilesTable = () => {
    const allFieldsAreValid = validationChecker();
    if (!allFieldsAreValid) {
      return;
    }
    if (allFieldsAreValid !== dashboardContext.form_state.valid) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          form_state: {
            valid: allFieldsAreValid,
            initial: false,
          },
        },
      });
    }

    const isProfileNameExist = tableProfiles
      .map((item) => item.profile_name)
      .includes(investorProfile.profile_name);

    if (isProfileNameExist) {
      setProfileNameExist(true);
      return;
    }
    setTableProfiles([...tableProfiles, investorProfile]);
    setInvestorProfile(initialInputFieldValues);
    setProfileNameExist(false);

    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        form_state: {
          initial: true,
          valid: false,
        },
      },
    });
  };

  const handleEditProfile = (profileName) => {
    const editingProfile = tableProfiles.find((item) => item.profile_name === profileName);
    setInvestorProfile(editingProfile);
    setTableProfiles((prevState) => {
      const newProfiles = prevState.filter((item) => item.profile_name !== profileName);
      return newProfiles;
    });
  };

  const handleSecondaryDeleteDialogClose = () => {
    setFileName("");
    setSecondaryDialogOpen(false);
  };

  const handleSecondaryDeleteDialogOpen = (profileName) => {
    setFileName(profileName);
    setSecondaryDialogOpen(true);
  };
  const handleDeleteProfile = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      handleSecondaryDeleteDialogClose();
      let newProfiles;
      setTableProfiles((prevState) => {
        newProfiles = prevState.filter((item) => item.profile_name !== fileName);
        return newProfiles;
      });
      const objToSave = {
        metadata: [
          {
            scope: "profile",
            data: {
              investorProfiles: newProfiles,
            },
          },
        ],
      };

      const response = await updateClient(selectedClientId, objToSave);
      if (response && tableProfiles.length === 0) {
        window.location.reload();
      } else if (response) {
        dispatch(setCurrentUser({ loading: false }));
      }
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  const handleUpdateClientData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      const objToSave = {
        metadata: [
          {
            scope: "profile",
            data: {
              investorProfiles: tableProfiles,
            },
          },
        ],
      };

      const response = await updateClient(selectedClientId, objToSave);

      if (response && tableProfiles.length === 0) {
        window.location.reload();
      } else if (response) {
        dispatch(setCurrentUser({ loading: false }));
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  // to manage the sidebar STATUS step, which one will be visible or hided
  const handleStatus = (id, readOnly = false) => {
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        advisor_content: {
          ...dashboardContext.investor_content,
          status_selected_transaction_id: id,
          details_selected_transaction_id: id,
          showStatus: true,
          showDetails: !readOnly && true,
          currentStep: "STATUS",
          readOnly,
        },
      },
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "0 auto",
        maxWidth: theme.breakpoints.values.xl,
      }}
    >
      {/* Card - 1 */}
      <Section title={t("investor/common:content.investor_content.transaction_offers.headline")}>
        <Card>
          {loadingTransactions && <LoadingIndicator type={"COMPONENT"} />}
          {!loadingTransactions && (
            <>
              {expandedTransactionOffers?.length > 0 ? (
                <OverviewInvestorTable
                  columns={table_1_columns}
                  data={expandedTransactionOffers}
                  newRegister={true}
                  handleShowStatus={handleStatus}
                  loading={loadingTransactions}
                />
              ) : (
                <Typography>
                  {t("investor/common:content.investor_content.transaction_offers.no_offer")}
                </Typography>
              )}
            </>
          )}
        </Card>
      </Section>

      {/* Card - 2 */}
      <Section title={t("investor/common:content.investor_content.investment_profile.headline")}>
        <ProfileForm
          investorProfile={investorProfile}
          isValid={isValid}
          handleInvestorProfileChange={handleInvestorProfileChange}
          handleCategoriesSelect={handleCategoriesSelect}
          handleAutoCompleteSelect={handleAutoCompleteSelect}
          handleConfirmCheck={handleConfirmCheck}
          handleAddProfileToProfilesTable={handleAddProfileToProfilesTable}
          profileNameExist={profileNameExist}
          initial={initial}
          setInitial={setInitial}
        />
        <Card>
          {tableProfiles.length === 0 ? (
            <Typography>
              {t(
                "investor/common:content.investor_content.investment_profile.profile_table.no_profile"
              )}
            </Typography>
          ) : (
            <>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                {t(
                  "investor/common:content.investor_content.investment_profile.profile_table.caption"
                )}
              </Typography>
              {tableProfiles.length > 0 &&
                tableProfiles?.map((profile, index) => (
                  <Accordion
                    key={profile.profile_name + "_" + index}
                    expanded={
                      expandAccordion === profile.profile_name || index === tableProfiles.length - 1
                    }
                    onChange={(e, isExpanded) =>
                      handleChangeExpandAccordion(e, isExpanded, profile.profile_name)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`${profile.profile_name}-content`}
                      sx={{ backgroundColor: rgba(theme.palette.secondary.main, 0.1) }}
                    >
                      {profile.profile_name}
                    </AccordionSummary>
                    <AccordionDetails>
                      <ProfileTable
                        profileObj={profile}
                        handleEditProfile={handleEditProfile}
                        handleDeleteProfile={(profileName) =>
                          handleSecondaryDeleteDialogOpen(profileName)
                        }
                      />
                    </AccordionDetails>
                    <SecondaryDialog
                      type="warning"
                      dialogTitle={t(
                        "platform/common:transaction_tables.deal_documents.delete_file"
                      )}
                      contentText={
                        <Trans
                          i18nKey="transaction_tables.deal_documents.delete_file_dialog_content_text"
                          values={{ fileName }}
                        />
                      }
                      secondaryDialogOpen={secondaryDialogOpen}
                      secondaryDialogClose={handleSecondaryDeleteDialogClose}
                      eventHandler={handleDeleteProfile}
                      actionButtonText={t("platform/common:misc.delete")}
                    />
                  </Accordion>
                ))}
            </>
          )}
        </Card>

        <Stack direction="row" justifyContent="flex-end" width="100%">
          <Button
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              py: 1,
              fontWeight: "bold",
              color: theme.palette.primary.main,
              borderRadius: "4px",
            }}
            onClick={() => {
              handleUpdateClientData();
              setInitial(false);
            }}
          >
            {t("misc.save")}
          </Button>
        </Stack>
      </Section>
    </Box>
  );
};

export default OverviewInvestor;
