import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    alignitems: "center",
    flexGrow: 1,
    justifyContent: "flex-end",
    padding: "0",
    gridColumnGap: "10px",
    [theme.breakpoints.up("md")]: {
      gridColumnGap: "15px",
      padding: "0 15px 0 0",
    },
    "& > *": {
      maxWidth: "max-content",
    },
  };
});

export const User = styled("div")(({ theme }) => {
  return {
    position: "relative",
  };
});

export const CompanyName = styled("div")(({ theme }) => {
  return {
    fontSize: "14px",
    lineHeight: "25px",
    fontWeight: "bold",
    display: "flex",
    gridColumnGap: "10px",
    [theme.breakpoints.up("md")]: {
      gridColumnGap: "15px",
      fontSize: "17px",
    },
    cursor: "pointer",
    color: theme.palette.common.black,
    "& .company": {
      width: "min-content",
      maxWidth: "180px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };
});

export const UserName = styled("div")(({ theme }) => {
  return {
    fontSize: "13px",
    lineHeight: "10px",
    [theme.breakpoints.up("md")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    color: theme.palette.grey[600],
  };
});

export const ListWrapper = styled("div")(({ theme }) => {
  return {
    backgroundColor: theme.palette.grey[50],
    borderRadius: "10px",
    position: "fixed",
    top: 0,
    right: 0,
    transform: "translate(-20px, 80px)",
    padding: "15px 20px",
    [theme.breakpoints.up("md")]: {
      transform: "translate(-35px, 95px)",
      padding: "15px 30px",
    },
    boxShadow: `0.1rem 0.1rem 0.6rem ${rgba(theme.palette.greys.fourth.main, 0.4)}`,
  };
});

export const List = styled("ul")(({ theme }) => {
  return {
    listStyle: "none",
    padding: 0,
    margin: 0,
    width: "130px",
    [theme.breakpoints.up("md")]: {
      width: "180px",
    },
  };
});

export const ListItem = styled("li")(({ theme }) => {
  return {
    listStyle: "none",
    padding: "5px 0",
    [theme.breakpoints.up("md")]: {
      padding: "10px 0",
    },
    margin: 0,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    cursor: "pointer",
  };
});
