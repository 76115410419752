import React, { useEffect, useContext, useState, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import parsePhoneNumber from "libphonenumber-js";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

import {
  Card,
  LoadingIndicator,
  Section,
  DetailsCompanyCard,
  DetailsContactCard,
  DetailsCompanyInformationCard,
  DetailsInterestCard,
  DetailsShareholderCard,
  DetailsMarketAnalysisCard,
  DetailsCompanyAdditionalInfoCard,
  DocumentsTable,
  DownloadFileCard,
} from "../../elements/frontend/src/components";
import { useClients, useTransactions, useDocuments } from "../../elements/frontend/src/hooks";
import { getValueFromMetadata } from "../../elements/frontend/src/common";

import { DashbordContext, DashboardDispatch } from "../../views/Private/Home";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";

const DetailsInvestor = () => {
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();

  const { getClient, updateClient } = useClients();
  const { getTransactionById, updateTransaction } = useTransactions();
  const { getDocumentPlatform } = useDocuments();

  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const products_dummy = [
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
    {
      product_name: "",
      revenue_type: "",
      sales_volume: 0,
    },
  ];

  const [detailsTransaction, setDetailsTransaction] = useState({});
  const [clientData, setClientData] = useState({});
  const [customerProducts, setCustomerProducts] = useState();
  const [disableSaveButton, setDisableSaveButton] = useState(true);

  const [initial, setInitial] = useState(true);

  // to manage to validate all fields
  const [formState /* setFormState */] = useState({
    initial: true,
    valid: false,
  });
  const [loadingDetails, setLoadingDetails] = useState(false);

  const ContentRef = useRef();
  const CompanyRef = useRef();
  const ContactRef = useRef();
  const CompanyInformationRef = useRef();
  const InterestRef = useRef();
  const ShareHolderRef = useRef();
  const MarketAnalysisRef = useRef();
  const CompanyAdditionalRef = useRef();
  const DocumentsManagementRef = useRef();
  //const CapitalSecondRef = useRef();

  useEffect(() => {
    if (dashboardContext.scrollTo) return;
    window.scrollTo(0, 0);
  }, [dashboardContext.scrollTo]);

  useEffect(() => {
    if (!dashboardContext.scrollTo) return;

    const refs = {
      ContentRef,
      CompanyRef,
      ContactRef,
      CompanyInformationRef,
      InterestRef,
      ShareHolderRef,
      MarketAnalysisRef,
      CompanyAdditionalRef,
      DocumentsManagementRef,
    };

    const top = refs[dashboardContext.scrollTo].current?.offsetTop;
    const SCROLL_THRESHOLD = 135;
    const contentTop = ContentRef.current?.scrollTop;

    if (contentTop !== undefined && top !== undefined) {
      if (contentTop + SCROLL_THRESHOLD === top) {
        window.scrollBy({
          top: -1 * SCROLL_THRESHOLD,
          behavior: "smooth",
        });
        setTimeout(() => {
          window.scrollTo({
            top: top - SCROLL_THRESHOLD,
            behavior: "smooth",
          });
          return;
        }, 100);
      }
    }
    if (top !== undefined) {
      ContentRef.current &&
        window.scrollTo({
          top: top - SCROLL_THRESHOLD,
          behavior: "smooth",
        });
    }
  }, [dashboardContext.scrollTo]);

  useEffect(() => {
    setLoadingDetails(true);
    getTransactionById(dashboardContext.details_selected_transaction_id)
      .then((response) => {
        // to assign the values of period, phase, stake and type fields initially
        let transactionObj = response.transaction;

        getClient(transactionObj.client_id).then((clientResponse) => {
          const scope_profile = clientResponse.client?.metadata?.find(
            (item) => item.scope === "profile"
          );
          const products = scope_profile?.data?.products_data || products_dummy;

          // it will add period, phase, stake and type as investor profile data in transaction invest field.
          if (
            !transactionObj?.invest?.period &&
            !transactionObj?.invest?.phase &&
            !transactionObj?.invest?.stake &&
            !transactionObj?.invest?.type
          ) {
            transactionObj = {
              ...transactionObj,
              invest: {
                ...transactionObj.invest,
                period: "",
                phase: "",
                stake: currentUser.datastore.companyStake.map((item) => item.value),
                type: currentUser.datastore.types.map((item) => item.value),
              },
            };
          }

          setClientData(clientResponse.client);
          setDetailsTransaction(transactionObj);
          setCustomerProducts(products);
          setLoadingDetails(false);
          dashboardDispatch({
            type: "UPDATE_DATA",
            payload: {
              detailsContentLoading: false,
            },
          });
        });
      })
      .catch((err) => {
        setLoadingDetails(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categoriesFromMetadata = useMemo(() => {
    const value = getValueFromMetadata("profile", "categories", clientData?.metadata);
    if (!value) {
      return [];
    }
    return value;
  }, [clientData?.metadata]);

  // It is used in cunjuction with handleCategoriesSelect
  // aim is to prevent duplication and check categories item is exist in result array.
  function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
        return true;
      }
    }
    return false;
  }

  // to update clientData state
  const handleClientDataChange = (e, field, isMetadata = false) => {
    const value = e.target.value;
    const name = e.target.name;
    setClientData((prevState) => {
      if (isMetadata) {
        const isScopeExist = clientData?.metadata?.find((item) => item.scope === field);

        if (!isScopeExist) {
          if (!prevState?.metadata) {
            prevState.metadata = [];
          }

          prevState.metadata.push({ scope: field, data: { [name]: value } });
        }

        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === field) {
            if (field === "susa") {
              // to convert string value with thousandseperator to number value
              const numberValue = value.split(".").join("");
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: numberValue,
                },
              };
            } else {
              return {
                ...item,
                data: {
                  ...item.data,
                  [name]: value,
                },
              };
            }
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      } else if (field === "name") {
        return {
          ...prevState,
          [field]: value,
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to update detailsTransaction state
  const handleTransactionDataChange = (e, field) => {
    const value = e.target.value;
    const name = e.target.name;

    const isFieldExist = detailsTransaction.hasOwnProperty(field);

    setDetailsTransaction((prevState) => {
      if (!isFieldExist) {
        prevState[field] = {
          [name]: value,
        };
      }

      if (field === "invest") {
        // to convert string value with thousandseperator to number value
        const numberValue = value.split(".").join("");
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: numberValue,
          },
        };
      } else {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: value,
          },
        };
      }
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle change event for trade_registration
  const handleTradeRegistrationChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { [name]: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { [name]: [] } }],
          };
        });
      }
    }

    // pattern will be HRA-12345 / HRB-12345
    if (value.length === 0) {
      value = "HR";
    }
    if (value.length === 3) {
      if (value.toLowerCase().endsWith("a") || value.toLowerCase().endsWith("b")) {
        value = value.toUpperCase() + "-";
      }
    }

    setClientData((prevState) => {
      const metaData = prevState?.metadata?.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              [name]: value,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  const handlePhoneChange = (value) => {
    setClientData((prevState) => {
      return {
        ...prevState,
        contact: {
          ...prevState.contact,
          telephone: value,
        },
      };
    });
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value, initial = true) => {
    if (initial && formState.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone.isValid();
  };

  // handle change event in categories
  const handleCategoriesSelect = (e, value, type, subCategoryValues = []) => {
    // if there is no metadata in clientData, then create scope "profile" with categories field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { categories: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { categories: [] } }],
          };
        });
      }
    }

    // handle change for first Autocomplete , which is "categories"
    if (type === "categories") {
      const newValue = value.map((item) => ({ cat: item.cat }));

      const uniqueNewValue = [...new Set(newValue.map((item) => item.cat))];
      const uniqueContext = [...new Set(categoriesFromMetadata.map((item) => item.cat))];

      // if item is removed from categories. It is for old customers
      if (uniqueContext.length > uniqueNewValue.length) {
        const filteredNewValues = categoriesFromMetadata.filter((item) =>
          uniqueNewValue.includes(item.cat)
        );

        setClientData((prevState) => {
          const metaData = prevState.metadata.map((item) => {
            // field is the scope in metadata
            if (item.scope === "profile") {
              return {
                ...item,
                data: {
                  ...item.data,
                  categories: filteredNewValues,
                },
              };
            }
            return item;
          });
          return {
            ...prevState,
            metadata: metaData,
          };
        });

        // if new item is added to categories. It is for old customers
      } else if (uniqueContext.length < uniqueNewValue.length) {
        let difference = uniqueNewValue.filter((x) => !uniqueContext.includes(x));
        const newValueArray = [
          ...categoriesFromMetadata,
          ...difference.map((item) => ({ cat: item })),
        ];

        setClientData((prevState) => {
          if (!clientData?.metadata) {
            clientData.metadata = [];
          }
          const metaData = prevState?.metadata?.map((item) => {
            // field is the scope in metadata
            if (item.scope === "profile") {
              return {
                ...item,
                data: {
                  ...item.data,
                  categories: newValueArray,
                },
              };
            }
            return item;
          });
          return {
            ...prevState,
            metadata: metaData,
          };
        });

        // It is for new customers
      } else {
        setClientData((prevState) => {
          if (!clientData?.metadata) {
            clientData.metadata = [];
          }
          const metaData = prevState?.metadata?.map((item) => {
            // field is the scope in metadata
            if (item.scope === "profile") {
              return {
                ...item,
                data: {
                  ...item.data,
                  categories: newValue,
                },
              };
            }
            return item;
          });
          return {
            ...prevState,
            metadata: metaData,
          };
        });
      }

      // handle change for second Autocomplete , which is "subCategories"
    } else if (type === "subCategories") {
      const newValue = value.map((item) => ({ cat: item.cat, ind: item.ind, des: item.des }));
      const result = [];

      // if item is removed from subCategories. It is for old customers
      if (newValue.length < subCategoryValues.length) {
        result.push(...newValue);

        // if new item is added to subCategories. It is for old customers
      } else if (newValue.length > subCategoryValues.length) {
        categoriesFromMetadata.forEach((catItem) => {
          newValue.forEach((subCatItem) => {
            if (subCatItem.cat === catItem.cat) {
              if (!catItem.hasOwnProperty("ind")) {
                if (containsObject(catItem, result)) {
                  const indValue = result.indexOf(catItem);
                  result.splice(indValue, 1);
                }
                result.push(subCatItem);
              } else if (!containsObject(subCatItem, result)) {
                result.push(subCatItem);
              }
            } else {
              if (!containsObject(catItem, result)) {
                if (!catItem.hasOwnProperty("ind") && !catItem.hasOwnProperty("des")) {
                  result.push(catItem);
                }
              }
            }
          });
        });
      }

      setClientData((prevState) => {
        const metaData = prevState.metadata.map((item) => {
          // field is the scope in metadata
          if (item.scope === "profile") {
            return {
              ...item,
              data: {
                ...item.data,
                categories: result,
              },
            };
          }
          return item;
        });
        return {
          ...prevState,
          metadata: metaData,
        };
      });
    }
    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }
  };

  // handle the changes on products table
  const handleProductDataChange = (e, index) => {
    const name = e.target.name;
    let value = e.target.value;
    value = value.split(".").join("");
    let newData = [];

    setCustomerProducts((prevState) => {
      newData = prevState.map((item, ind) => {
        if (ind === index) {
          return {
            ...item,
            [name]: value,
          };
        } else {
          return item;
        }
      });
      return newData;
    });

    // to make save button enable
    if (disableSaveButton) {
      setDisableSaveButton(false);
    }

    // if there is no metadata in clientData, then create scope "profile" with products_data field.
    // This is a common case for advisor clients
    if (!clientData?.metadata) {
      setClientData((prevState) => {
        return {
          ...prevState,
          metadata: [{ scope: "profile", data: { products_data: [] } }],
        };
      });
    } else {
      // metadata exists, but let's check scope="profile" exists or not
      const isScopeProfileExist = clientData?.metadata?.find((item) => item.scope === "profile");
      // if not exist
      if (!isScopeProfileExist) {
        setClientData((prevState) => {
          return {
            ...prevState,
            metadata: [...prevState.metadata, { scope: "profile", data: { products_data: [] } }],
          };
        });
      }
    }

    setClientData((prevState) => {
      const metaData = prevState.metadata.map((item) => {
        // field is the scope in metadata
        if (item.scope === "profile") {
          return {
            ...item,
            data: {
              ...item.data,
              products_data: newData,
            },
          };
        }
        return item;
      });
      return {
        ...prevState,
        metadata: metaData,
      };
    });
  };

  const handleInvestorProfileAutoCompleteSelect = (e, value, field) => {
    if (value) {
      if (field === "phase" || field === "period") {
        // syntax of value is {value : "...", summary: "..."}
        const newValue = value.value;

        setDetailsTransaction((prevState) => {
          return {
            ...prevState,
            invest: {
              ...prevState.invest,
              [field]: newValue,
            },
          };
        });
        // to make save button enable
        if (disableSaveButton) {
          setDisableSaveButton(false);
        }
      } else {
        // syntax of value is [{value : "...", summary: "..."}, {value : "...", summary: "..."}]
        const newValue = value.map((item) => item.value);

        setDetailsTransaction((prevState) => {
          return {
            ...prevState,
            invest: {
              ...prevState.invest,
              [field]: newValue,
            },
          };
        });
        // to make save button enable
        if (disableSaveButton) {
          setDisableSaveButton(false);
        }
      }
    }
  };

  // to update the client and transaction data in DB
  const handleUpdateClientTransactionData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (clientData.contact.telephone) {
        const phone = parsePhoneNumber(clientData.contact.telephone);
        const isValidPhoneNumber = phone.isValid();
        const formattedNumber = phone.formatInternational();

        if (isValidPhoneNumber) {
          setClientData((prevState) => {
            return {
              ...prevState,
              contact: {
                ...prevState.contact,
                telephone: formattedNumber,
              },
            };
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      // update client data
      const responseClient = await updateClient(clientData.client_id, clientData);

      // update transaction data
      const responseTransaction = await updateTransaction(
        detailsTransaction.transaction_id,
        detailsTransaction
      );

      if (responseClient || responseTransaction) {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  const handleDownloadDocument = async (type) => {
    dispatch(
      setCurrentUser({
        loading: true,
      })
    );
    const customer_id = clientData.client_id;

    getDocumentPlatform(type, customer_id)
      .then((response) => {
        const a = document.createElement("a");
        a.href = response.download_url;
        a.download = "report";
        a.click();

        // update transaction state "avv_received" and "nda_received" after downloading
        const transaction_id = dashboardContext.transactions[0].transaction_id;
        let objTransactionData = {};
        if (type === "avv") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  avv_received: true,
                },
              },
            ],
          };
        } else if (type === "nda_company") {
          objTransactionData = {
            metadata: [
              {
                scope: "state",
                data: {
                  nda_received: true,
                },
              },
            ],
          };
        }

        updateTransaction(transaction_id, objTransactionData).then((responseTransaction) => {
          if (responseTransaction) {
            dispatch(
              setCurrentUser({
                loading: false,
              })
            );
          }
        });
      })
      .catch((err) => {
        dispatch(
          setCurrentUser({
            loading: false,
          })
        );
      });
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: theme.breakpoints.values.xl,
      }}
      ref={ContentRef}
    >
      <DetailsCompanyCard
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        handleTradeRegistrationChange={handleTradeRegistrationChange}
        loading={loadingDetails}
        ref={CompanyRef}
      />
      <DetailsContactCard
        transaction={detailsTransaction}
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        handlePhoneChange={handlePhoneChange}
        checkPhoneNumber={checkPhoneNumber}
        loading={loadingDetails}
        ref={ContactRef}
      />
      <DetailsCompanyInformationCard
        clientData={clientData}
        productsData={customerProducts}
        handleClientDataChange={handleClientDataChange}
        handleCategoriesSelect={handleCategoriesSelect}
        handleProductDataChange={handleProductDataChange}
        loading={loadingDetails}
        ref={CompanyInformationRef}
        initial={initial}
        setInitial={setInitial}
      />
      <DetailsInterestCard
        clientData={clientData}
        transaction={detailsTransaction}
        setDetailsTransaction={setDetailsTransaction}
        disableSaveButton={disableSaveButton}
        setDisableSaveButton={setDisableSaveButton}
        handleTransactionDataChange={handleTransactionDataChange}
        handleInvestorProfileAutoCompleteSelect={handleInvestorProfileAutoCompleteSelect}
        loading={loadingDetails}
        ref={InterestRef}
      />
      <DetailsShareholderCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        loading={loadingDetails}
        ref={ShareHolderRef}
      />
      <DetailsMarketAnalysisCard
        transaction={detailsTransaction}
        handleTransactionDataChange={handleTransactionDataChange}
        loading={loadingDetails}
        ref={MarketAnalysisRef}
      />

      <DetailsCompanyAdditionalInfoCard
        clientData={clientData}
        handleClientDataChange={handleClientDataChange}
        loading={loadingDetails}
        ref={CompanyAdditionalRef}
      />

      <Section title={t("company/common:onboarding.documents.headline")}>
        <Card>
          <Stack direction="row" spacing={2}>
            <DownloadFileCard
              type={"avv"}
              fileName={t("company/common:onboarding.documents.avv")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={"nda_company"}
              fileName={t("company/common:onboarding.documents.nda")}
              handleDownloadDocument={handleDownloadDocument}
            />
            <DownloadFileCard
              type={"contract_company"}
              fileName={t("company/common:client_agreement.documents.client_agreement")}
              handleDownloadDocument={handleDownloadDocument}
            />
          </Stack>
        </Card>
      </Section>

      <Section
        title={t("details_a3.documents_management_card.headline")}
        ref={DocumentsManagementRef}
      >
        <Card>
          {loadingDetails && <LoadingIndicator type={"COMPONENT"} />}
          {!loadingDetails && (
            <DocumentsTable
              files={detailsTransaction?.files}
              product={"platform"}
              page={"details"}
            />
          )}
        </Card>
      </Section>

      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          size="medium"
          disabled={disableSaveButton}
          sx={{
            px: { xs: 3, md: 6 },
            py: 1,
            mr: 1,
            mb: 5,
            mt: 2,
            fontWeight: "bold",
            color: theme.palette.primary.main,
            borderRadius: "4px",
          }}
          onClick={() => {
            handleUpdateClientTransactionData();
            setInitial(false);
          }}
        >
          {t("misc.save")}
        </Button>
      </Stack>
    </Box>
  );
};

export default DetailsInvestor;
