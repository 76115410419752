import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { Section, Card, LoadingIndicator, UserManagementInContact } from "../";

import { Phone } from "../Phone/Phone";

import TConfig from "../../../../../config";

export const DetailsContactCard = React.forwardRef(
  (
    {
      transaction,
      clientData,
      handleClientDataChange,
      handlePhoneChange,
      checkPhoneNumber,
      loading,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const client_id = transaction?.client_id;
    const customer_id = transaction?.customer_id;

    // in order to differentiate transacations by "company transaction" or "advisor client transaction!"
    const transactionType = clientData?.subscription?.product_id;

    return (
      <Section title={t("details_a3.contact_card.headline")} ref={ref}>
        <Card>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={t("details_a3.contact_card.first_name")}
                  name="first_name"
                  id="details_a3.contact_card_first_name"
                  value={clientData?.contact?.first_name || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "contact")}
                  inputProps={{
                    autoComplete: "new-firstname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("details_a3.contact_card.last_name")}
                  name="last_name"
                  id="details_a3.contact_card_last_name"
                  value={clientData?.contact?.last_name || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "contact")}
                  inputProps={{
                    autoComplete: "new-lastname",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2, mb: 3 }}>
                <Phone
                  telephone={clientData?.contact?.telephone || "+49"}
                  label={t("details_a3.contact_card.phone")}
                  phoneChange={handlePhoneChange}
                  mobile={true}
                  error={!checkPhoneNumber(clientData?.contact?.telephone)}
                  errorText={
                    !checkPhoneNumber(clientData?.contact?.telephone)
                      ? t("error.invalid_phoneNumber")
                      : ""
                  }
                  registering={false}
                  idLabel={"details_contact_card"}
                />
              </Stack>
              {/** ******** USER MANAGEMENT IN CONTACT CARD  ************/}
              <UserManagementInContact
                customerId={customer_id}
                clientId={client_id}
                transactionType={transactionType}
                product={TConfig.defaults.PRODUCT_ID}
              />
            </>
          )}
        </Card>
      </Section>
    );
  }
);
