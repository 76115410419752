import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { Card, LoadingIndicator } from "../";
import { Section } from "../Section/Section";

import { Transaction } from "../../Store/api";

interface DetailShareholderCardProps {
  transaction: Transaction;
  handleTransactionDataChange: (event: React.ChangeEvent, field: string) => void;
  loading: boolean;
}
export const DetailsShareholderCard = React.forwardRef(
  (
    { transaction, handleTransactionDataChange, loading }: DetailShareholderCardProps,
    ref: React.ForwardedRef<unknown>
  ) => {
    const { t } = useTranslation();

    return (
      <Section title={t("details_a3.shareholder_card.headline")} ref={ref}>
        <Card>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={t("details_a3.shareholder_card.largest_sh_pct")}
                  name="largest_sh_pct"
                  id="details_a3.shareholder_card_largest_sh_pct"
                  value={transaction?.captable?.largest_sh_pct || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleTransactionDataChange(e, "captable")}
                  inputProps={{
                    autoComplete: "new-largest_sh_pct",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("details_a3.shareholder_card.largest_sh_name")}
                  name="largest_sh_name"
                  id="details_a3.shareholder_card_largest_sh_name"
                  value={transaction?.captable?.largest_sh_name || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleTransactionDataChange(e, "captable")}
                  inputProps={{
                    autoComplete: "new-shareholder_name",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
            </>
          )}
        </Card>
      </Section>
    );
  }
);
