import React from "react";
import { useTranslation, Trans } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/de";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { Section, Card, LoadingIndicator } from "../";
import { thousandSeparator, getValueFromMetadata } from "../../common";

export const DetailsCompanyAdditionalInfoCard = React.forwardRef(
  ({ clientData, handleClientDataChange, loading }, ref) => {
    const { t } = useTranslation();

    const last_year = dayjs().year() - 1;
    const prev_year = dayjs().year() - 2;

    return (
      <Section title={t("details_a3.company_add_info_card.headline")} ref={ref}>
        <Card>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mb: 2 }}
              >
                <TextField
                  label={
                    <Trans
                      i18nKey="details_a3.company_add_info_card.sales_prev_year"
                      values={{ prev_year }}
                    />
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name="sales_prev_year"
                  id="company_add_info_card_sales_prev_year"
                  value={
                    thousandSeparator(
                      getValueFromMetadata("susa", "sales_prev_year", clientData?.metadata)
                    ) || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "susa", true)}
                />
                <TextField
                  label={
                    <Trans
                      i18nKey="details_a3.company_add_info_card.sales_last_year"
                      values={{ last_year }}
                    />
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name="sales_last_year"
                  id="company_add_info_card_sales_last_year"
                  value={
                    thousandSeparator(
                      getValueFromMetadata("susa", "sales_last_year", clientData?.metadata)
                    ) || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "susa", true)}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={
                    <Trans
                      i18nKey="details_a3.company_add_info_card.ebitda_prev_year"
                      values={{ prev_year }}
                    />
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name="ebitda_prev_year"
                  id="company_add_info_card_ebitda_prev_year"
                  value={
                    thousandSeparator(
                      getValueFromMetadata("susa", "ebitda_prev_year", clientData?.metadata)
                    ) || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "susa", true)}
                />
                <TextField
                  label={
                    <Trans
                      i18nKey="details_a3.company_add_info_card.ebitda_last_year"
                      values={{ last_year }}
                    />
                  }
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name="ebitda_last_year"
                  id="company_add_info_card_ebitda_last_year"
                  value={
                    thousandSeparator(
                      getValueFromMetadata("susa", "ebitda_last_year", clientData?.metadata)
                    ) || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "susa", true)}
                />
              </Stack>
            </>
          )}
        </Card>
      </Section>
    );
  }
);
