import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import { CurrencyFormatter } from "../../elements/frontend/src/hooks/formatter/CurrencyFormatter/CurrencyFormatter";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";

import { Card } from "../../elements/frontend/src/components";
import { CategoriesDropdown } from "../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown";

import { FieldSet } from "./styled.overviewInvestor";
import { useSelector } from "react-redux";

const ProfileForm = ({
  investorProfile,
  isValid,
  handleInvestorProfileChange,
  handleCategoriesSelect,
  handleAutoCompleteSelect,
  handleConfirmCheck,
  handleAddProfileToProfilesTable,
  profileNameExist,
  initial,
  setInitial,
}) => {
  const { t } = useTranslation(["investor/common"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const currentUser = useSelector(({ currentUser }) => currentUser);

  // Country options for select "Land"
  const countries = t("investor/common:content.countries", {
    returnObjects: true,
  });
  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));
  // to convert Autocomplete array values to objects with value, summary fields
  const convertToObjValue = useCallback(
    (value, field) => {
      if (value?.length === 0) return [];
      return value?.map((item) => {
        if (field === "region") {
          return countriesArr.find((obj) => obj.code === item);
        } else {
          return currentUser.datastore[field].find((obj) => obj.value === item);
        }
      });
    },
    [countriesArr, currentUser.datastore]
  );

  return (
    <Card>
      <Stack sx={{ mb: 2 }}>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 4 }}>
          {/*** PROFILE NAME ***/}
          <TextField
            label={t(
              "investor/common:content.investor_content.investment_profile.profile.profile_name"
            )}
            name="profile_name"
            id="profile_name"
            value={investorProfile.profile_name || ""}
            size="small"
            fullWidth
            required
            onChange={handleInvestorProfileChange}
            error={!isValid("profile_name") || profileNameExist}
            helperText={
              !isValid("profile_name")
                ? t("misc.required_field")
                : profileNameExist
                ? t("error.profilename_exist")
                : ""
            }
            inputProps={{
              autoComplete: "new-profile_name",
              form: {
                autoComplete: "off",
              },
            }}
          />
        </Stack>

        {/*** CATEGORIES ***/}
        <CategoriesDropdown
          selectedCategoryIds={investorProfile.categories || []}
          selectedSubCategoryIds={investorProfile.sub_categories || []}
          withSubcategories={true}
          isValid={isValid}
          handleCategoriesSelect={handleCategoriesSelect}
          type="single"
          initial={initial}
          setInitial={setInitial}
        />
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ my: 2 }}>
          {/*** PHASES ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="phases"
              freeSolo
              options={currentUser.datastore.phases}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investorProfile.phases, "phases") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t(
                    "investor/common:content.investor_content.investment_profile.profile.phases"
                  )}
                  error={!isValid("phases")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "phases")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("phases") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>

          {/*** REGION ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="region"
              freeSolo
              options={countriesArr}
              getOptionLabel={(option) => countries[option.code]}
              value={convertToObjValue(investorProfile.region, "region") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t(
                    "investor/common:content.investor_content.investment_profile.profile.region"
                  )}
                  error={!isValid("region")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "region")}
              isOptionEqualToValue={(option, value) => option.code === value.code}
            />
            {!isValid("region") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
          {/*** INVEST ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="invest"></InputLabel>
            <TextField
              required
              id="invest"
              name="invest"
              label={t("investor/common:content.investment.invest")}
              labelId="invest"
              InputProps={{
                inputComponent: CurrencyFormatter,
              }}
              inputProps={{
                currency: "EUR",
                prefix: t("investor/common:content.investment.prefix_investment"),
                name: "invest",
                inputMode: "numeric",
              }}
              value={(currentUser.datastore.invest && investorProfile.invest) || ""}
              size="small"
              fullWidth
              onChange={handleInvestorProfileChange}
              error={!isValid("invest")}
            />

            {!isValid("invest") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>

          {/*** REVENUE ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="revenue" required>
              {t("investor/common:content.investor_content.investment_profile.profile.revenue")}
            </InputLabel>
            <Select
              labelId="revenue"
              id="revenue"
              name="revenue"
              value={currentUser.datastore.revenue && (investorProfile.revenue || "")} // to fix the warning related MUI [Select] You have provided an out-of-range value.
              label={t(
                "investor/common:content.investor_content.investment_profile.profile.revenue"
              )}
              onChange={handleInvestorProfileChange}
              error={!isValid("revenue")}
            >
              {currentUser.datastore.revenue &&
                currentUser.datastore.revenue.map(({ value, summary }, index) => (
                  <MenuItem value={value} key={index}>
                    {summary}
                  </MenuItem>
                ))}
            </Select>
            {!isValid("revenue") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
          {/*** EBITDA ***/}
          <FormControl fullWidth size="small">
            <InputLabel id="ebitda" required>
              {t("investor/common:content.investor_content.investment_profile.profile.ebitda")}
            </InputLabel>
            <Select
              labelId="ebitda"
              id="ebitda"
              name="ebitda"
              value={currentUser.datastore.revenue && (investorProfile.ebitda || "")} // to fix the warning related MUI [Select] You have provided an out-of-range value.
              label={t(
                "investor/common:content.investor_content.investment_profile.profile.ebitda"
              )}
              onChange={handleInvestorProfileChange}
              error={!isValid("ebitda")}
            >
              {currentUser.datastore.ebitda &&
                currentUser.datastore.ebitda.map(({ value, summary }, index) => (
                  <MenuItem value={value} key={index}>
                    {summary}
                  </MenuItem>
                ))}
            </Select>
            {!isValid("ebitda") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
          </FormControl>

          {/*** STAKE ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="stake"
              freeSolo
              options={currentUser.datastore.stake}
              getOptionLabel={(option) => option?.summary}
              value={convertToObjValue(investorProfile?.stake, "stake") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t(
                    "investor/common:content.investor_content.investment_profile.profile.stake"
                  )}
                  error={!isValid("stake")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "stake")}
              isOptionEqualToValue={(option, value) => option?.summary === value.summary}
            />
            {!isValid("stake") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mb: 2 }}>
          {/*** TYPES ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="types"
              freeSolo
              options={currentUser.datastore.types}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investorProfile.types, "types") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t(
                    "investor/common:content.investor_content.investment_profile.profile.types"
                  )}
                  error={!isValid("types")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "types")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("types") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
          {/*** PERIOD ***/}
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="period"
              freeSolo
              options={currentUser.datastore.period}
              getOptionLabel={(option) => option.summary}
              value={convertToObjValue(investorProfile.period, "period") || []}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label={t(
                    "investor/common:content.investor_content.investment_profile.profile.period"
                  )}
                  error={!isValid("period")}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                />
              )}
              onChange={(e, value) => handleAutoCompleteSelect(e, value, "period")}
              isOptionEqualToValue={(option, value) => option.summary === value.summary}
            />
            {!isValid("period") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
          {/*** CONTRIBUTION TO THE COMPANY ***/}
          <Stack
            sx={{
              width: "100%",
            }}
          >
            <FieldSet>
              <legend>
                {t(
                  "investor/common:content.investor_content.investment_profile.profile.contribution.legend"
                )}
              </legend>
              <FormControlLabel
                control={
                  <Checkbox
                    value="strategic_investor"
                    color="primary"
                    size="small"
                    checked={investorProfile?.contribution?.strategic_investor || false}
                    onChange={handleConfirmCheck("contribution", "strategic_investor")}
                  />
                }
                label={
                  <Typography variant="body1">
                    {t(
                      "investor/common:content.investor_content.investment_profile.profile.contribution.strategic_investor"
                    )}
                  </Typography>
                }
                sx={{ width: "100%" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="financial_investor"
                    color="primary"
                    size="small"
                    checked={investorProfile?.contribution?.financial_investor || false}
                    onChange={handleConfirmCheck("contribution", "financial_investor")}
                  />
                }
                label={
                  <Typography variant="body1">
                    {t(
                      "investor/common:content.investor_content.investment_profile.profile.contribution.financial_investor"
                    )}
                  </Typography>
                }
                sx={{ width: "100%" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    value="wachstums_investor"
                    color="primary"
                    size="small"
                    checked={investorProfile?.contribution?.wachstums_investor || false}
                    onChange={handleConfirmCheck("contribution", "wachstums_investor")}
                  />
                }
                label={
                  <Typography variant="body1">
                    {t(
                      "investor/common:content.investor_content.investment_profile.profile.contribution.wachstums_investor"
                    )}
                  </Typography>
                }
                sx={{ width: "100%" }}
              />
            </FieldSet>
          </Stack>

          {/*** INVESTMENT HORIZON ***/}
          <Stack
            sx={{
              width: "100%",
            }}
          ></Stack>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          size={isMobile ? "small" : "medium"}
          sx={{
            px: 3,
            py: 1,
            fontWeight: "bold",
            color: theme.palette.primary.main,
            borderRadius: "4px",
          }}
          onClick={handleAddProfileToProfilesTable}
        >
          {t("misc.add")}
        </Button>
      </Stack>
    </Card>
  );
};

export default ProfileForm;
