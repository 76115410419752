import React from "react";
import { useSelector } from "react-redux";

import { FadeIn } from "../../elements/frontend/src/components";

import InitialContent from "./Initial";
import InvestorContent from "./Investor";

import { Wrapper } from "./styled.content";

const Content = () => {
  const currentUser = useSelector(({ currentUser }) => currentUser);
  return (
    <Wrapper>
      <FadeIn>
        {!currentUser.investor_approved &&
          currentUser.current_main_content === "initial_content" && <InitialContent />}
        {currentUser.investor_approved &&
          currentUser.current_main_content === "investor_content" && <InvestorContent />}
      </FadeIn>
    </Wrapper>
  );
};

export default Content;
