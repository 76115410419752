/* eslint-disable no-fallthrough */
import React, { useCallback, useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { Checkbox, FormControlLabel, FormHelperText, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";

import { Section, Card, Phone } from "../../../elements/frontend/src/components";
import { useClients } from "../../../elements/frontend/src/hooks";
import { isValidLengthString } from "../../../elements/frontend/src/common";
import { CurrencyFormatter } from "../../../elements/frontend/src/hooks/formatter/CurrencyFormatter/CurrencyFormatter";

import { DashbordContext } from "../../../views/Private/Home";
import { DashboardDispatch } from "../../../views/Private/Home";
import { Wrapper, FieldSet } from "./styled.initial";
import { setCurrentUser } from "../../../elements/frontend/src/Store/currentUser/currentUserSlice";
import { CategoriesDropdown } from "../../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown";
import { InvestorWelcomeDialog } from "../../../elements/frontend/src/components/InvestorWelcomeDialog/InvestorWelcomeDialog";

import { useGetCategoryTreesQuery } from "../../../elements/frontend/src/Store/api/datastore/categories/categoriesApi";
import { removeAllChildSubCategoriesWithoutSelectedRootCategory } from "../../../elements/frontend/src/components/CategoriesDropdown/CategoriesDropdown.utils";
import { CategoryType } from "../../../elements/frontend/src/components/CategoriesDropdown/interfaces";

const InitialContent = () => {
  const { t } = useTranslation(["investor/common"]);
  const { updateClient } = useClients();
  const [trigger, setTrigger] = useState(false);

  const dashboardContext = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();

  const categoryTreesQuery = useGetCategoryTreesQuery();
  const categoryTrees = categoryTreesQuery.data;

  const [isComplete, setIsComplete] = useState(false);
  const [initial, setInitial] = useState(true);

  const handleWelcomeDialogClose = () => {
    setIsComplete(false);
    window.location.reload();
  };

  const selectedClientId = currentUser.selectedClient.client_id;

  // Country options for select "Land"
  const countries = t("investor/common:content.countries", {
    returnObjects: true,
  });
  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));
  const convertToObjValue = useCallback(
    (value, field) => {
      if (!value || value?.length === 0) return [];
      return value.map((item) => {
        if (field === "region") {
          return countriesArr.find((obj) => obj.code === item);
        } else {
          return currentUser.datastore[field].find((obj) => obj.value === item);
        }
      });
    },
    [countriesArr, currentUser.datastore]
  );

  // to check whether input fields are valid or not
  const isValid = (name, initial = true) => {
    if (initial && dashboardContext.form_state.initial) return true;
    switch (name) {
      case "company_name":
        return isValidLengthString(dashboardContext[name], 1);
      case "street":
      case "postal":
      case "city":
      case "country":
        return isValidLengthString(dashboardContext.address[name], 1);
      case "first_name":
      case "last_name":
        return isValidLengthString(dashboardContext.contact[name], 1);
      case "telephone":
        return isValidLengthString(dashboardContext.contact[name], 5);
      case "categories":
      case "sub_categories":
      case "phases":
      case "region":
      case "stake":
      case "types":
      case "period": {
        if (dashboardContext.investment[name] !== undefined)
          return dashboardContext.investment[name].length > 0;
      }
      case "invest":
      case "revenue":
      case "ebitda":
        return isValidLengthString(dashboardContext.investment[name], 1);
      default:
        return false;
    }
  };

  // to validate phone number with libphonenumber-js
  const checkPhoneNumber = (value, initial = true) => {
    if (initial && dashboardContext.form_state.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone.isValid();
  };

  // handle change event
  const handleDataChange = (e, mainField) => {
    const field = e.target.name;
    const value = e.target.value;

    if (mainField === "company_name") {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          [field]: value,
        },
      });
    } else {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          [mainField]: {
            ...dashboardContext[mainField],
            [field]: value,
          },
        },
      });
    }
  };

  // handle change event in contact telephone
  const handlePhoneChange = (value) => {
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        contact: {
          ...dashboardContext.contact,
          telephone: value,
        },
      },
    });
  };

  // handle change event in Autocomplete elements
  const handleAutoCompleteSelect = (e, value, field) => {
    if (value) {
      const newValue = value.map((item) => (field === "region" ? item.code : item.value));

      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          investment: {
            ...dashboardContext.investment,
            [field]: newValue,
          },
        },
      });
    }
  };

  // to check whether checkboxes checked or not
  const handleConfirmCheck = (field, chkName) => {
    return (e) => {
      const isChecked = e.target.checked;

      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          investment: {
            ...dashboardContext.investment,
            [field]: {
              ...dashboardContext.investment[field],
              [chkName]: isChecked,
            },
          },
        },
      });
    };
  };

  // handle change event in CategoriesDropdown component
  const handleCategoriesSelect = (categories, type) => {
    // handle change for "categories"
    const categoryIds = categories.map((category) => category.value);

    if (type === CategoryType.CATEGORY && categoryTrees) {
      const subCategoryIds = dashboardContext.investment.sub_categories || [];
      const updatedSubCategoryIds = removeAllChildSubCategoriesWithoutSelectedRootCategory(
        categoryIds,
        subCategoryIds,
        categoryTrees
      );
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          investment: {
            ...dashboardContext.investment,
            categories: categoryIds,
            sub_categories: updatedSubCategoryIds,
          },
        },
      });
    }

    if (type === CategoryType.SUB_CATEGORY) {
      dashboardDispatch({
        type: "UPDATE_DATA",
        payload: {
          investment: {
            ...dashboardContext.investment,
            sub_categories: categoryIds,
          },
        },
      });
    }
  };

  const handleUpdateClientData = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (dashboardContext.form_state.initial) {
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            form_state: {
              initial: false,
              valid: dashboardContext.form_state.valid,
            },
          },
        });
      }

      const allFieldsAreValid =
        isValid("company_name", false) &&
        isValid("street", false) &&
        isValid("postal", false) &&
        isValid("city", false) &&
        isValid("country", false) &&
        isValid("first_name", false) &&
        isValid("last_name", false) &&
        isValid("telephone", false) &&
        isValid("categories", false) &&
        isValid("sub_categories", false) &&
        isValid("phases", false) &&
        isValid("region", false) &&
        isValid("invest", false) &&
        isValid("revenue", false) &&
        isValid("ebitda", false) &&
        isValid("stake", false) &&
        isValid("types", false) &&
        isValid("period", false);

      if (!allFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (allFieldsAreValid !== dashboardContext.form_state.valid) {
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            form_state: {
              valid: allFieldsAreValid,
              initial: false,
            },
          },
        });
      }

      if (dashboardContext.contact.telephone) {
        const phone = parsePhoneNumber(dashboardContext.contact.telephone);
        const isValidPhoneNumber = phone.isValid();
        const formattedNumber = phone.formatInternational();

        if (isValidPhoneNumber) {
          dashboardDispatch({
            type: "UPDATE_DATA",
            payload: {
              contact: {
                ...dashboardContext.contact,
                telephone: formattedNumber,
              },
            },
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      if (allFieldsAreValid) {
        setIsComplete(true);
        const objToSave = {
          name: dashboardContext.company_name,
          address: {
            line_1: dashboardContext.address.street || "",
            postal: dashboardContext.address.postal || "",
            city: dashboardContext.address.city || "",
            country: dashboardContext.address.country || "",
          },
          contact: {
            first_name: dashboardContext.contact.first_name || "",
            last_name: dashboardContext.contact.last_name || "",
            telephone: dashboardContext.contact.telephone || "",
          },
          metadata: [
            {
              scope: "investment",
              data: {
                categories: dashboardContext.investment.categories || [],
                sub_categories: dashboardContext.investment.sub_categories || [],
                phases: dashboardContext.investment.phases || [],
                region: dashboardContext.investment.region || [],
                invest: dashboardContext.investment.invest || "",
                revenue: dashboardContext.investment.revenue || "",
                ebitda: dashboardContext.investment.ebitda || "",
                stake: dashboardContext.investment.stake || [],
                types: dashboardContext.investment.types || [],
                period: dashboardContext.investment.period || [],
                contribution: {
                  strategic_investor:
                    dashboardContext.investment.contribution.strategic_investor || false,
                  financial_investor:
                    dashboardContext.investment.contribution.financial_investor || false,
                  wachstums_investor:
                    dashboardContext.investment.contribution.wachstums_investor || false,
                },
              },
            },
          ],
        };

        const response = await updateClient(selectedClientId, objToSave);
        if (response) {
          //window.location.reload();
          setIsComplete(true);
        }
      }
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  return (
    <Wrapper>
      <Box
        component="form"
        sx={{
          px: { xs: 2, md: 4 },
          py: { xs: 1, md: 3 },
          width: "100%",
          maxWidth: theme.breakpoints.values.xl,
        }}
      >
        {/****** COMPANY DATA  ************/}
        <Section title={t("investor/common:content.company.headline")}>
          <Card>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              <TextField
                label={t("investor/common:content.company.company_name")}
                name="company_name"
                id="company_name"
                value={dashboardContext.company_name || ""}
                size="small"
                fullWidth
                onChange={(e) => handleDataChange(e, "company_name")}
                inputProps={{
                  autoComplete: "new-company",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ my: 2 }}
            >
              <TextField
                label={t("investor/common:content.company.street")}
                name="street"
                id="street"
                value={dashboardContext.address.street || ""}
                size="small"
                required
                fullWidth
                onChange={(e) => handleDataChange(e, "address")}
                onBlur={() => {
                  const containNumAndText =
                    /[a-zA-Z]/.test(dashboardContext.address.street) &&
                    /\d/.test(dashboardContext.address.street);
                  if (!containNumAndText) {
                    setTrigger(true);
                  } else {
                    setTrigger(false);
                  }
                }}
                error={!isValid("street") || trigger}
                helperText={!isValid("street") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-street",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              <TextField
                label={t("investor/common:content.company.postal_code")}
                name="postal"
                id="postal"
                value={
                  dashboardContext?.address?.country === "DEU"
                    ? (dashboardContext?.address?.postal || "").substring(0, 5)
                    : dashboardContext?.address?.postal || ""
                }
                size="small"
                required
                fullWidth
                onChange={(e) => handleDataChange(e, "address")}
                error={!isValid("postal")}
                helperText={!isValid("postal") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-postal",
                  form: {
                    autoComplete: "off",
                  },
                  maxLength: dashboardContext?.address?.country === "DEU" ? 5 : undefined,
                  onInput: (e) => {
                    e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
                  },
                }}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              <TextField
                label={t("investor/common:content.company.city")}
                name="city"
                id="city"
                value={dashboardContext.address.city || ""}
                size="small"
                required
                fullWidth
                onChange={(e) => handleDataChange(e, "address")}
                error={!isValid("city")}
                helperText={!isValid("city") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-city",
                  form: {
                    autoComplete: "off",
                  },
                  onInput: (e) => {
                    e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z]/g, "");
                  },
                }}
              />
              <FormControl fullWidth size="small">
                <InputLabel id="country" required>
                  {t("investor/common:content.company.country")}
                </InputLabel>
                <Select
                  labelId="country"
                  id="country"
                  name="country"
                  value={dashboardContext.address.country || ""}
                  label={t("investor/common:content.company.country")}
                  onChange={(e) => handleDataChange(e, "address")}
                >
                  {countriesArr.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
                {!isValid("country") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
              </FormControl>
            </Stack>
          </Card>
        </Section>

        {/****** CONTACT ************/}
        <Section title={t("investor/common:content.contact.headline")}>
          <Card>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              <TextField
                label={t("investor/common:content.contact.first_name")}
                name="first_name"
                id="first_name"
                value={dashboardContext.contact.first_name || ""}
                size="small"
                required
                fullWidth
                onChange={(e) => handleDataChange(e, "contact")}
                error={!isValid("first_name")}
                helperText={!isValid("first_name") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-firstname",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
              <TextField
                label={t("investor/common:content.contact.last_name")}
                name="last_name"
                id="last_name"
                value={dashboardContext.contact.last_name || ""}
                size="small"
                required
                fullWidth
                onChange={(e) => handleDataChange(e, "contact")}
                error={!isValid("last_name")}
                helperText={!isValid("last_name") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-lastname",
                  form: {
                    autoComplete: "off",
                  },
                }}
              />
            </Stack>
            <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 2 }}>
              <Phone
                telephone={dashboardContext.contact.telephone || "+49"}
                label={t("investor/common:content.contact.phone")}
                phoneChange={handlePhoneChange}
                mobile={true}
                error={
                  !isValid("telephone") || !checkPhoneNumber(dashboardContext.contact.telephone)
                }
                errorText={
                  !isValid("telephone")
                    ? t("misc.required_field")
                    : !checkPhoneNumber(dashboardContext.contact.telephone)
                    ? t("error.invalid_phoneNumber")
                    : ""
                }
                registering={false}
              />
            </Stack>
          </Card>
        </Section>

        {/********** INVESTMENT SECTION ************/}
        <Section title={t("investor/common:content.investment.headline")}>
          <Card>
            {/*** CATEGORIES ***/}
            <CategoriesDropdown
              selectedCategoryIds={dashboardContext.investment.categories || []}
              selectedSubCategoryIds={dashboardContext.investment.sub_categories || []}
              withSubcategories={true}
              isValid={isValid}
              handleCategoriesSelect={handleCategoriesSelect}
              initial={initial}
              setInitial={setInitial}
            />
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mt: 2 }}
            >
              {/*** PHASES ***/}
              <FormControl fullWidth size="small">
                <Autocomplete
                  multiple
                  id="phases"
                  freeSolo
                  options={currentUser.datastore.phases}
                  getOptionLabel={(option) => option.summary}
                  value={convertToObjValue(dashboardContext.investment.phases, "phases") || []}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("investor/common:content.investment.phases")}
                      error={!isValid("phases")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                  onChange={(e, value) => handleAutoCompleteSelect(e, value, "phases")}
                  isOptionEqualToValue={(option, value) => option.summary === value.summary}
                />
                {!isValid("phases") && (
                  <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                )}
                <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                  {t("misc.multi_select")}
                </Typography>
              </FormControl>

              {/*** REGION ***/}
              <FormControl fullWidth size="small">
                <Autocomplete
                  multiple
                  id="region"
                  freeSolo
                  options={countriesArr}
                  getOptionLabel={(option) => countries[option.code]}
                  value={convertToObjValue(dashboardContext.investment.region, "region")}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("investor/common:content.investment.region")}
                      error={!isValid("region")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                  onChange={(e, value) => {
                    handleAutoCompleteSelect(e, value, "region");
                  }}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                />
                {!isValid("region") && (
                  <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                )}
                <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                  {t("misc.multi_select")}
                </Typography>
              </FormControl>
            </Stack>
          </Card>

          {/********** INVESTMENT 2ND CARD ************/}
          <Card>
            <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
              {/*** INVEST ***/}
              <FormControl fullWidth size="small">
                <InputLabel id="invest"></InputLabel>
                <TextField
                  required
                  id="invest"
                  name="invest"
                  label={t("investor/common:content.investment.invest")}
                  labelId="invest"
                  InputProps={{
                    inputComponent: CurrencyFormatter,
                  }}
                  inputProps={{
                    currency: "EUR",
                    prefix: t("investor/common:content.investment.prefix_investment"),
                    name: "invest",
                    inputMode: "numeric",
                  }}
                  value={(currentUser.datastore.invest && dashboardContext.investment.invest) || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleDataChange(e, "investment")}
                  error={!isValid("invest")}
                />

                {!isValid("invest") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
              </FormControl>

              {/*** REVENUE ***/}
              <FormControl fullWidth size="small">
                <InputLabel id="revenue" required>
                  {t("investor/common:content.investment.revenue")}
                </InputLabel>
                <Select
                  labelId="revenue"
                  id="revenue"
                  name="revenue"
                  value={
                    currentUser.datastore.revenue && (dashboardContext.investment.revenue || "")
                  } // to fix the warning related MUI [Select] You have provided an out-of-range value.
                  label={t("investor/common:content.investment.revenue")}
                  onChange={(e) => handleDataChange(e, "investment")}
                  error={!isValid("revenue")}
                >
                  {currentUser.datastore.revenue &&
                    currentUser.datastore.revenue.map(({ value, summary }, index) => (
                      <MenuItem value={value} key={index}>
                        {summary}
                      </MenuItem>
                    ))}
                </Select>
                {!isValid("revenue") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mt: 2 }}
            >
              {/*** EBITDA ***/}
              <FormControl fullWidth size="small">
                <InputLabel id="ebitda" required>
                  {t("investor/common:content.investment.ebitda")}
                </InputLabel>
                <Select
                  labelId="ebitda"
                  id="ebitda"
                  name="ebitda"
                  value={
                    currentUser.datastore.revenue && (dashboardContext.investment.ebitda || "")
                  } // to fix the warning related MUI [Select] You have provided an out-of-range value.
                  label={t("investor/common:content.investment.ebitda")}
                  onChange={(e) => handleDataChange(e, "investment")}
                  error={!isValid("ebitda")}
                >
                  {currentUser.datastore.ebitda &&
                    currentUser.datastore.ebitda.map(({ value, summary }, index) => (
                      <MenuItem value={value} key={index}>
                        {summary}
                      </MenuItem>
                    ))}
                </Select>
                {!isValid("ebitda") && <FormHelperText>{t("misc.required_field")}</FormHelperText>}
              </FormControl>

              {/*** STAKE ***/}
              <FormControl fullWidth size="small">
                <Autocomplete
                  multiple
                  id="stake"
                  freeSolo
                  options={currentUser.datastore.stake}
                  getOptionLabel={(option) => option.summary}
                  value={convertToObjValue(dashboardContext.investment.stake, "stake") || []}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("investor/common:content.investment.stake")}
                      error={!isValid("stake")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                  onChange={(e, value) => handleAutoCompleteSelect(e, value, "stake")}
                  isOptionEqualToValue={(option, value) => option.summary === value.summary}
                />
                {!isValid("stake") && (
                  <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                )}
                <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                  {t("misc.multi_select")}
                </Typography>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mt: 2 }}
            >
              {/*** TYPES ***/}
              <FormControl fullWidth size="small">
                <Autocomplete
                  multiple
                  id="types"
                  freeSolo
                  options={currentUser.datastore.types}
                  getOptionLabel={(option) => option.summary}
                  value={convertToObjValue(dashboardContext.investment.types, "types") || []}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("investor/common:content.investment.types")}
                      error={!isValid("types")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                  onChange={(e, value) => handleAutoCompleteSelect(e, value, "types")}
                  isOptionEqualToValue={(option, value) => option.summary === value.summary}
                />
                {!isValid("types") && (
                  <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                )}
                <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                  {t("misc.multi_select")}
                </Typography>
              </FormControl>
              {/*** PERIOD ***/}
              <FormControl fullWidth size="small">
                <Autocomplete
                  multiple
                  id="period"
                  freeSolo
                  options={currentUser.datastore.period}
                  getOptionLabel={(option) => option.summary}
                  value={convertToObjValue(dashboardContext.investment.period, "period") || []}
                  size="small"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={t("investor/common:content.investment.period")}
                      error={!isValid("period")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  )}
                  onChange={(e, value) => handleAutoCompleteSelect(e, value, "period")}
                  isOptionEqualToValue={(option, value) => option.summary === value.summary}
                />
                {!isValid("period") && (
                  <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
                )}
                <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                  {t("misc.multi_select")}
                </Typography>
              </FormControl>
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 4 }}
              sx={{ mt: 2 }}
            >
              {/*** CONTRIBUTION TO THE COMPANY ***/}
              <Stack
                sx={{
                  width: "100%",
                }}
              >
                <FieldSet>
                  <legend>{t("investor/common:content.investment.contribution.legend")}</legend>

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="strategic_investor"
                        color="primary"
                        size="small"
                        checked={dashboardContext.investment.contribution.strategic_investor}
                      />
                    }
                    label={
                      <Typography variant="body1">
                        {t("investor/common:content.investment.contribution.strategic_investor")}
                      </Typography>
                    }
                    onChange={handleConfirmCheck("contribution", "strategic_investor")}
                    sx={{ width: "100%" }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="financial_investor"
                        color="primary"
                        size="small"
                        checked={dashboardContext.investment.contribution.financial_investor}
                      />
                    }
                    label={
                      <Typography variant="body1">
                        {t("investor/common:content.investment.contribution.financial_investor")}
                      </Typography>
                    }
                    onChange={handleConfirmCheck("contribution", "financial_investor")}
                    sx={{ width: "100%" }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="wachstums_investor"
                        color="primary"
                        size="small"
                        checked={dashboardContext.investment.contribution.wachstums_investor}
                      />
                    }
                    label={
                      <Typography variant="body1">
                        {t("investor/common:content.investment.contribution.wachstums_investor")}
                      </Typography>
                    }
                    onChange={handleConfirmCheck("contribution", "wachstums_investor")}
                    sx={{ width: "100%" }}
                  />
                </FieldSet>
              </Stack>
              <Stack
                sx={{
                  width: "100%",
                }}
              ></Stack>
            </Stack>
          </Card>
        </Section>

        <InvestorWelcomeDialog
          type="primary"
          dialogTitle={t("investor/common:content.welcome.title")}
          contentText={t("investor/common:content.welcome.welcome_message")}
          secondaryDialogOpen={isComplete}
          secondaryDialogClose={handleWelcomeDialogClose}
          eventHandler={handleWelcomeDialogClose}
          actionButtonText={"OK"}
        />

        <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { xs: 3, md: 6 },
              py: 1,
              mr: 1,
              mb: 5,
              mt: 2,
              fontWeight: "bold",
              color: theme.palette.primary.main,
              borderRadius: "4px",
            }}
            onClick={() => {
              handleUpdateClientData();
              setInitial(false);
            }}
          >
            {t("misc.save")}
          </Button>
        </Stack>
      </Box>
    </Wrapper>
  );
};

export default InitialContent;
