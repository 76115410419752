import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  disableSortBy,
} from "react-table";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";

import { useTransactions } from "../../elements/frontend/src/hooks";
import {
  LoadingIndicator,
  fuzzyTextFilterFn,
  DefaultColumnFilter,
  GlobalFilter,
} from "../../elements/frontend/src/components";
import { DashbordContext } from "../../views/Private/Home";
import {
  Wrapper,
  StyledTableCell,
  StyledTableRow,
  StyledTextField,
  StyledSelect,
} from "./styled.tableComp";
import { setCurrentUser } from "../../elements/frontend/src/Store/currentUser/currentUserSlice";

export const OverviewInvestorTable = ({
  columns,
  data,
  handleShowStatus = () => {},
  handleShowDetails = () => {},
  onlyDetails = false,
  newRegister = false,
  loading = false,
}) => {
  const { t } = useTranslation(["advisor/common"]);
  const { updateTransaction } = useTransactions();
  const dashboardContext = useContext(DashbordContext);
  const currentUser = useSelector(({ currentUser }) => currentUser);
  const dispatch = useDispatch();
  const [transactionData, setTransactionData] = useState([]);

  // update state transactionData with props data
  useEffect(() => {
    if (data) {
      setTransactionData(data);
    }
  }, [data]);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    prepareRow,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: transactionData,
      initialState: { pageIndex: 0, pageSize: 10 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      disableSortBy,
    },
    useFilters, // useFilters!  We need to place useFilters before useSortBy.
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  // to convert advisor user's name to id
  const convertNameToId = (name) => {
    const advisor = dashboardContext?.advisor_content?.advisorUserOptions?.find(
      (item) => item.name === name
    );
    return advisor.user_id;
  };

  // advisorUsers select element change event handler
  const handleSelectChangeEvent = (e, rowIndex, columnId, transactionId) => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      const value = e.target.value;
      const transaction = transactionData.find((item) => item.transaction_id === transactionId);
      // it is user_id of user
      const id = convertNameToId(value);
      const objectToUpdate = {
        advisor: {
          ...transaction.investor,
          user_id: id,
        },
      };
      // update transaction in DB
      updateTransaction(transactionId, objectToUpdate).then((response) => {
        if (response) {
          // update in state transactionData
          setTransactionData((old) =>
            old.map((row, index) => {
              if (index === rowIndex) {
                return {
                  ...old[rowIndex],
                  [columnId]: value,
                };
              }
              return row;
            })
          );
          dispatch(setCurrentUser({ loading: false }));
        }
      });
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  // to accept transaction offer

  const handleApproveTransactionOffer = (transactionId) => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      if (transactionId) {
        const transaction = transactionData.find((item) => item.transaction_id === transactionId);
        const userId = currentUser.user.user_id;
        const objectToUpdate = {
          investor: {
            ...transaction.investor,
            user_id: userId,
          },
          metadata: [
            {
              scope: "state",
              data: {
                investor_accepted: true,
              },
            },
          ],
        };

        // update transaction in DB
        updateTransaction(transactionId, objectToUpdate).then((response) => {
          if (response) {
            window.location.reload();
          }
        });
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  // to reject transaction offer

  const handleRejectTransactionOffer = (transactionId) => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      if (transactionId) {
        const objectToUpdate = {
          metadata: [
            {
              scope: "state",
              data: {
                investor_matching: false,
              },
            },
          ],
        };

        // update transaction in DB
        updateTransaction(transactionId, objectToUpdate).then((response) => {
          if (response) {
            window.location.reload();
          }
        });
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      <Wrapper>
        {loading && <LoadingIndicator type={"COMPONENT"} />}
        {!loading && (
          <>
            <Table {...getTableProps()} size="small">
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      if (column.id === "accept") {
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        return (
                          <th
                            {...column.getHeaderProps(column.getSortByToggleProps())}
                            style={{
                              textAlign: "center",
                            }}
                          >
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}
                            </span>
                            {/* Render the columns filter UI */}
                            <div>{column.canFilter ? column.render("Filter") : null}</div>
                          </th>
                        );
                      } else {
                        return (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render("Header")}
                            {/* Add a sort direction indicator */}
                            <span>
                              {column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}
                            </span>
                            {/* Render the columns filter UI */}
                            <div>{column.canFilter ? column.render("Filter") : null}</div>
                          </th>
                        );
                      }
                    })}
                    <th
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {t("transaction_tables.columns.action")}
                    </th>
                  </TableRow>
                ))}
                <TableRow>
                  <th
                    colSpan={visibleColumns.length + 1}
                    style={{
                      textAlign: "left",
                    }}
                  >
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </th>
                </TableRow>
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <StyledTableRow {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        if (cell.column.id === "assigned_investor_user") {
                          if (
                            dashboardContext?.investor_content?.investorUserOptions?.length === 1
                          ) {
                            return (
                              <StyledTableCell {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </StyledTableCell>
                            );
                          }
                          if (dashboardContext?.investor_content?.investorUserOptions?.length > 1) {
                            return (
                              <StyledTableCell {...cell.getCellProps()} key={index}>
                                <StyledSelect
                                  size="small"
                                  value={cell.value || ""}
                                  onChange={(e) =>
                                    handleSelectChangeEvent(
                                      e,
                                      cell.row.index,
                                      cell.column.id,
                                      row.original.transaction_id
                                    )
                                  }
                                  sx={{ minWidth: "100%" }}
                                >
                                  {dashboardContext?.investor_content?.investorUserOptions?.map(
                                    (menuItem, ind) => (
                                      <MenuItem key={ind} value={menuItem.name}>
                                        {menuItem.name}
                                      </MenuItem>
                                    )
                                  )}
                                </StyledSelect>
                              </StyledTableCell>
                            );
                          }
                        } else if (cell.column.id === "accept") {
                          return (
                            <StyledTableCell sx={{ textAlign: "center" }} key={index}>
                              <Button
                                variant="contained"
                                color="success"
                                startIcon={<DoneIcon fontSize="small" />}
                                sx={{ fontWeight: "bold", fontSize: "12px", padding: "5px 10px" }}
                                onClick={() =>
                                  handleApproveTransactionOffer(row.original.transaction_id)
                                }
                              >
                                {t("misc.approve")}
                              </Button>

                              <Button
                                variant="contained"
                                color="warning"
                                startIcon={<CancelIcon fontSize="small" />}
                                sx={{
                                  fontWeight: "bold",
                                  fontSize: "12px",
                                  padding: "5px 10px",
                                  marginLeft: "10px",
                                }}
                                onClick={() =>
                                  handleRejectTransactionOffer(row.original.transaction_id)
                                }
                              >
                                {t("misc.reject")}
                              </Button>
                            </StyledTableCell>
                          );
                        }

                        return (
                          <StyledTableCell {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </StyledTableCell>
                        );
                      })}

                      <StyledTableCell sx={{ textAlign: "center" }}>
                        {!onlyDetails && (
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleShowStatus(
                                row.original.transaction_id,
                                newRegister ? true : false
                              )
                            }
                            // to switch the read-only mode. If newRegister true, then it is read-only. ReadOnly is a property in dashboardContext.advisor_content.
                            // Status component will be read-only for the status details from Transaktionsangebote table.
                          >
                            <SummarizeOutlinedIcon color="primary" fontSize="small" />
                          </IconButton>
                        )}

                        {!newRegister && (
                          <IconButton
                            size="small"
                            onClick={() => handleShowDetails(row.original.transaction_id)}
                          >
                            <ManageSearchIcon color="primary" fontSize="small" />
                          </IconButton>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
            {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  gotoPage(0);
                }}
                disabled={!canPreviousPage}
                sx={{ minWidth: "35px", padding: "5px" }}
              >
                {"<<"}
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  previousPage();
                }}
                disabled={!canPreviousPage}
                sx={{ minWidth: "35px", padding: "5px" }}
              >
                {"<"}
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();

                  nextPage();
                }}
                disabled={!canNextPage}
                sx={{ minWidth: "35px", padding: "5px" }}
              >
                {">"}
              </Button>

              <Button
                size="small"
                variant="outlined"
                onClick={(e) => {
                  e.preventDefault();
                  gotoPage(pageCount - 1);
                }}
                disabled={!canNextPage}
                sx={{ minWidth: "35px", padding: "5px" }}
              >
                {">>"}
              </Button>

              <span style={{ fontSize: "15px" }}>
                {t("transaction_tables.pagination.page")}
                <strong>
                  {pageIndex + 1} {t("transaction_tables.pagination.of")} {pageOptions.length}
                </strong>
              </span>

              <span style={{ fontSize: "15px" }}>
                | {t("transaction_tables.pagination.go_to_page")}
              </span>

              <StyledTextField
                defaultValue={pageIndex + 1}
                type="number"
                onChange={(e) => {
                  e.preventDefault();
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                sx={{ width: "70px" }}
              />

              <StyledSelect
                size="small"
                value={pageSize}
                onChange={(e) => {
                  e.preventDefault();
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10, 20, 30, 40, 50].map((pageSize, i) => (
                  <MenuItem key={i} value={pageSize}>
                    {t("transaction_tables.pagination.show")} {pageSize}
                  </MenuItem>
                ))}
              </StyledSelect>
            </Stack>
          </>
        )}
      </Wrapper>
    </>
  );
};
