import React, { useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import { Section, Card, LoadingIndicator } from "../";
import { thousandSeparator } from "../../common";

//import TConfig from "../../../../../config";
import { CapitalSecondaryDetails } from "../CapitalSecondary/CapitalSecondaryDetails";
import { CurrencyFormatter } from "../../hooks/formatter/CurrencyFormatter/CurrencyFormatter";

export const DetailsInterestCard = React.forwardRef(
  (
    {
      transaction,
      setDetailsTransaction,
      handleTransactionDataChange,
      handleInvestorProfileAutoCompleteSelect,
      loading,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [optionValue, setOptionValue] = useState(null);
    const currentUser = useSelector(({ currentUser }) => currentUser);

    const minCap = parseFloat(transaction?.invest?.min_cap || "");
    const maxCap = parseFloat(transaction?.invest?.max_cap || "");
    const errorMessage = maxCap <= minCap ? t("details_a3.interest_card.max_cap_error") : "";

    // to convert Autocomplete array values to objects with value, summary fields
    const convertToObjValue = useCallback(
      (value, field) => {
        if (field === "investorPhase" || field === "period") {
          // phase and period have single selection
          const data = currentUser.datastore[field].find((obj) => obj.value === value);
          return data;
        } else if (field === "companyStake" || field === "types") {
          // stake and type have multi selection
          return value?.map((item) =>
            currentUser.datastore[field].find((obj) => obj.value === item)
          );
        } else if (field === "transactionStructure") {
          const data = currentUser.datastore[field].find((obj) => obj.value === value);
          return data;
        }
      },
      [currentUser.datastore]
    );

    // to get capital name according to code
    const getName = useCallback(
      (val) => {
        if (currentUser?.datastore?.transactionStructure.length === 0) return;
        const capital = currentUser?.datastore?.transactionStructure.find(
          (item) => item.value === val
        );
        return capital?.summary;
      },
      [currentUser?.datastore?.transactionStructure]
    );

    // in order to create investmentStructure options for the first AutoComplete Component
    const structureOptions = useMemo(() => {
      const items = [];
      for (const level1 of currentUser?.datastore?.transactionStructure) {
        items.push({
          cat: level1.value,
          summary: getName(level1.value),
        });
      }
      return items;
    }, [currentUser?.datastore?.transactionStructure, getName]);

    return (
      <>
        <Section title={t("details_a3.interest_card.headline")} ref={ref}>
          <Card>
            {loading && <LoadingIndicator type={"COMPONENT"} />}
            {!loading && (
              <>
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                  <TextField
                    label={t("details_a3.interest_card.capital_request_from")}
                    name="min_cap"
                    id="details_a3.interest_card_min_cap"
                    value={thousandSeparator(transaction?.invest?.min_cap)}
                    size="small"
                    fullWidth
                    onChange={(e) => handleTransactionDataChange(e, "invest")}
                    inputProps={{
                      autoComplete: "new-min_cap",
                      form: {
                        autoComplete: "off",
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                  <TextField
                    label={t("details_a3.interest_card.capital_request_to")}
                    name="max_cap"
                    id="details_a3.interest_card_max_cap"
                    value={thousandSeparator(transaction?.invest?.max_cap)}
                    size="small"
                    fullWidth
                    onChange={(e) => handleTransactionDataChange(e, "invest")}
                    error={errorMessage !== ""}
                    helperText={errorMessage}
                    inputProps={{
                      autoComplete: "new-max_cap",
                      form: {
                        autoComplete: "off",
                      },
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                </Stack>

                {/* spread the row to be into 2 column */}
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ my: 2 }}
                >
                  <TextField
                    label={t("details_a3.interest_card.company_value")}
                    name="self_ev"
                    id="details_a3.interest_card_self_ev"
                    value={thousandSeparator(transaction?.invest?.self_ev)}
                    size="small"
                    fullWidth
                    onChange={(e) => handleTransactionDataChange(e, "invest")}
                    InputProps={{
                      inputComponent: CurrencyFormatter,
                    }}
                    inputProps={{
                      currency: "EUR",
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                  />
                  {/** * PHASE ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_phase"
                      freeSolo
                      options={currentUser?.datastore?.investorPhase || []}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        convertToObjValue(transaction?.invest?.phase, "investorPhase") || {
                          value: "",
                          summary: "",
                        }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.phase")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "phase")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                      //open={true} //keep the field is always opened
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.select")}
                    </Typography>
                  </FormControl>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ my: 2 }}
                >
                  {/** * TYPES ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="details_a3.interest_card_type"
                      freeSolo
                      options={currentUser?.datastore?.types}
                      getOptionLabel={(option) => option?.summary}
                      value={convertToObjValue(transaction?.invest?.type, "types")}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.types")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "type")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>

                  {/** * STAKE ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="details_a3.interest_card_stake"
                      freeSolo
                      options={currentUser?.datastore?.companyStake}
                      getOptionLabel={(option) => option?.summary}
                      value={convertToObjValue(transaction?.invest?.stake, "companyStake")}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.stake")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "stake")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                </Stack>

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mt: 2 }}
                >
                  {/** * PERIOD * Investmenthorizont**/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_period"
                      freeSolo
                      options={currentUser?.datastore?.period}
                      getOptionLabel={(option) => option?.summary}
                      value={
                        convertToObjValue(transaction?.invest?.period, "period") || {
                          value: "",
                          summary: "",
                        }
                      }
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.period")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(e, value) =>
                        handleInvestorProfileAutoCompleteSelect(e, value, "period")
                      }
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.select")}
                    </Typography>
                  </FormControl>

                  {/* Investmentstruktur */}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      id="details_a3.interest_card_invest_structure"
                      freeSolo
                      options={currentUser?.datastore?.transactionStructure && structureOptions}
                      getOptionLabel={(option) => option?.summary}
                      value={optionValue}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("details_a3.interest_card.invest_structure")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(_e, newValue) => {
                        setOptionValue(newValue);
                      }}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.select")}
                    </Typography>
                  </FormControl>
                </Stack>
              </>
            )}
            {optionValue?.cat === "capital_secondary" || optionValue?.cat === "secondary" ? (
              <Stack sx={{ mt: 6 }} spacing={{ xs: 2, md: 4 }}>
                <CapitalSecondaryDetails
                  transactionId={transaction.transaction_id}
                  setDetailsTransaction={setDetailsTransaction}
                />
              </Stack>
            ) : null}
          </Card>
        </Section>
      </>
    );
  }
);
