import { styled } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100%",

  display: "flex",
  justifyContent: "center",
}));

export const FieldSet = styled("fieldset")(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.grey[400]}`,
  borderRadius: "4px",
  padding: "0 10px",
  "& legend": {
    fontSize: "12px",
    color: theme.palette.grey[700],
    padding: "0 7px",
  },
}));
