import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import IconButton from "@mui/material/IconButton";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { TableWrapper } from "./styled.overviewInvestor";

const ProfileTable = ({ profileObj, handleEditProfile, handleDeleteProfile }) => {
  const { t } = useTranslation(["investor/common"]);

  const currentUser = useSelector(({ currentUser }) => currentUser);

  const {
    profile_name,
    categories,
    sub_categories,
    phases,
    region,
    invest,
    revenue,
    ebitda,
    stake,
    types,
    period,
    contribution,
  } = profileObj;

  // Country options for select "Land"
  const countries = t("investor/common:content.countries", {
    returnObjects: true,
  });
  const ebitdaObj = t("investor/common:content.ebitda", {
    returnObjects: true,
  });

  const periodObj = t("investor/common:content.period", {
    returnObjects: true,
  });
  const phasesObj = t("investor/common:content.phases", {
    returnObjects: true,
  });
  const stakeObj = t("investor/common:content.stake", {
    returnObjects: true,
  });
  const revenueObj = t("investor/common:content.revenue", {
    returnObjects: true,
  });
  const typesObj = t("investor/common:content.types", {
    returnObjects: true,
  });

  // to get category name according to code
  const getName = useCallback(
    (val) => {
      if (currentUser.datastore.categories.length === 0) return;
      const category = currentUser.datastore.categories.find((item) => item.value === val);
      return category?.summary;
    },
    [currentUser.datastore.categories]
  );

  return (
    <TableWrapper>
      <tbody>
        <tr>
          <th>
            {t("investor/common:content.investor_content.investment_profile.profile.profile_name")}
          </th>
          <td>{profile_name}</td>
          <td className="action_buttons" rowSpan={13}>
            <IconButton
              size="small"
              aria-label="edit_profile"
              color="primary"
              onClick={() => handleEditProfile(profile_name)}
            >
              <EditOutlinedIcon />
            </IconButton>
            <IconButton
              size="small"
              aria-label="delete_profile"
              color="warning"
              onClick={() => handleDeleteProfile(profile_name)}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </td>
        </tr>
        <tr>
          <th>
            {t("investor/common:content.investor_content.investment_profile.profile.categories")}
          </th>
          <td>
            {categories?.length ? (
              <ul>
                {categories.map((item, i) => (
                  <li key={i}>{getName(item)}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>
            {t(
              "investor/common:content.investor_content.investment_profile.profile.sub_categories"
            )}
          </th>
          <td>
            {sub_categories?.length ? (
              <ul>
                {sub_categories.map((item, i) => (
                  <li key={i}>{getName(item)}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.region")}</th>
          <td>{region ? countries[region[0]] : ""}</td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.invest")}</th>
          <td>
            {invest
              ? `${t("investor/common:content.invest.base")} ${Number(invest).toLocaleString()} EUR`
              : ""}
          </td>
        </tr>
        <tr>
          <th>
            {t("investor/common:content.investor_content.investment_profile.profile.revenue")}
          </th>
          <td>{revenue ? revenueObj[revenue] : ""}</td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.ebitda")}</th>
          <td>{ebitda ? ebitdaObj[ebitda] : ""}</td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.phases")}</th>
          <td>
            {phases?.length ? (
              <ul className="space">
                {phases.map((item, i) => (
                  <li key={i}>{phasesObj[item]}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.types")}</th>
          <td>
            {types?.length ? (
              <ul className="space">
                {types.map((item, i) => (
                  <li key={i}>{typesObj[item]}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.stake")}</th>
          <td>
            {stake?.length ? (
              <ul className="space">
                {stake.map((item, i) => (
                  <li key={i}>{stakeObj[item]}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>{t("investor/common:content.investor_content.investment_profile.profile.period")}</th>
          <td>
            {period?.length ? (
              <ul className="space">
                {period.map((item, i) => (
                  <li key={i}>{periodObj[item]}</li>
                ))}
              </ul>
            ) : null}
          </td>
        </tr>
        <tr>
          <th>
            {t(
              "investor/common:content.investor_content.investment_profile.profile.contribution.legend"
            )}
          </th>
          <td>
            <ul className="space">
              {contribution?.strategic_investor && (
                <li>
                  {t(
                    "investor/common:content.investor_content.investment_profile.profile.contribution.strategic_investor"
                  )}
                </li>
              )}
              {contribution?.financial_investor && (
                <li>
                  {t(
                    "investor/common:content.investor_content.investment_profile.profile.contribution.financial_investor"
                  )}
                </li>
              )}
              {contribution?.wachstums_investor && (
                <li>
                  {t(
                    "investor/common:content.investor_content.investment_profile.profile.contribution.wachstums_investor"
                  )}
                </li>
              )}
            </ul>
          </td>
        </tr>
      </tbody>
    </TableWrapper>
  );
};

export default ProfileTable;
